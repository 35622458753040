import React, { useEffect } from 'react';
import useLocalStorage from '../hooks/use-local-storage';

export const LayoutContext = React.createContext({
  isMinimal: undefined,
  toggleTheme: () => null,
  changing: false,
  toggleChanging: () => null,
});

const isBrowser = typeof window !== 'undefined';
const MINIMAL_STORAGE = 'minimal';

// let forceMinimal = isBrowser && window.location.hash === '#m';
let forcePlayful = isBrowser && window.location.hash === '#p';

// if (forceMinimal) localStorage.setItem('theme', MINIMAL_STORAGE);
if (isBrowser) {
  if (forcePlayful) {
    localStorage.setItem('theme', '');
  } else {
    localStorage.setItem('theme', MINIMAL_STORAGE);
  }
}

const initialThemeValue = isBrowser && localStorage.getItem('theme');

export const LayoutProvider = (props) => {
  const [token, setToken] = useLocalStorage(
    'theme',
    initialThemeValue || 'minimal'
  );

  const [isMinimal, setMinimal] = React.useState(
    initialThemeValue === MINIMAL_STORAGE
  );
  const [changing, setChanging] = React.useState(true);

  const [showCreativeTransition, setShowCreativeTransition] = React.useState(
    false
  );
  const [showMinimalTransition, setShowMinimalTransition] = React.useState(
    false
  );

  const [firstLoadIn, setFirstLoadIn] = React.useState(true); // 'in' animation for the first load complete
  const [firstLoadOut, setFirstLoadOut] = React.useState(true); // 'in' animation for the first load complete

  const [firstAnimateIn, setFirstAnimateIn] = React.useState(true);

  // Get the initial theme on the preferences if it exists
  React.useEffect(() => {
    if (token === MINIMAL_STORAGE) {
      setMinimal(true);
      setShowMinimalTransition(true);
    } else {
      setShowCreativeTransition(true);
    }
    // setFirstLoad(false);
  }, []);

  // store the preference
  React.useEffect(() => {
    if (isMinimal && token !== MINIMAL_STORAGE) {
      setToken(String(MINIMAL_STORAGE));
    } else if (!isMinimal && token === MINIMAL_STORAGE) {
      setToken('');
    }
  }, [isMinimal]);

  // Toggle the theme
  const toggleTheme = () => {
    if (typeof window !== 'undefined' && window.gtag) {
      window.gtag('event', 'toggle-theme', {
        theme: isMinimal ? 'playful' : 'minimal',
      });
    }
    setMinimal(!isMinimal);
  };

  const toggleChanging = () => {
    if (!changing) {
      if (isMinimal) {
        setShowCreativeTransition(true);
      } else {
        setShowMinimalTransition(true);
      }
    } else {
      setShowCreativeTransition(false);
      setShowMinimalTransition(false);
    }

    setChanging(!changing);
  };

  return (
    <LayoutContext.Provider
      value={{
        isMinimal,
        toggleTheme,
        changing,
        toggleChanging,
        firstAnimateIn,
        setFirstAnimateIn,
        firstLoadIn,
        setFirstLoadIn,
        firstLoadOut,
        setFirstLoadOut,
        showCreativeTransition,
        showMinimalTransition,
      }}
    >
      {props.children}
    </LayoutContext.Provider>
  );
};

export const useLayoutContext = () => {
  const {
    isMinimal,
    toggleTheme,
    changing,
    toggleChanging,
    firstAnimateIn,
    setFirstAnimateIn,
    firstLoadIn,
    setFirstLoadIn,
    firstLoadOut,
    setFirstLoadOut,
    showCreativeTransition,
    showMinimalTransition,
  } = React.useContext(LayoutContext);
  return {
    isMinimal,
    toggleTheme,
    changing,
    toggleChanging,
    firstAnimateIn,
    setFirstAnimateIn,
    firstLoadIn,
    setFirstLoadIn,
    firstLoadOut,
    setFirstLoadOut,
    showCreativeTransition,
    showMinimalTransition,
  };
};
