import { useState } from 'react';

function useLocalStorage(key, initialValue) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    if (typeof window === 'undefined') {
      return initialValue;
    }

    let item = null;

    try {
      item = window.localStorage.getItem(key);
    } catch (error) {
      // console.log('no item, return initial', error);
      return initialValue;
    }

    if (item) {
      try {
        let jsonItem = JSON.parse(item);
        return jsonItem;
      } catch (error) {
        // console.log('item not json: return item', error);
        return item;
      }
    } else {
      // console.log('no item, return initial');
      return initialValue;
    }
  });
  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      if (typeof window !== 'undefined') {
        window.localStorage.setItem(key, JSON.stringify(valueToStore));
      }
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };
  return [storedValue, setValue];
}

export default useLocalStorage;
