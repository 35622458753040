import React, { useEffect } from 'react';
import { SEO } from '../components/seo';
import { LayoutProvider } from '../contexts/layoutContext';

const Minimal = () => {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.location = '/#m';
    }
  }, []);

  return (
    <LayoutProvider>
      <SEO
        image={`https://images.ctfassets.net/1wmnm7ky08ew/14ykWTQRFE3Vsoenvq6hH6/85180b0b9332ab4876cb5ef77a1a1e0e/shaz1.jpg`}
      />
    </LayoutProvider>
  );
};

export default Minimal;

// export const Head = () => <SEO />;
